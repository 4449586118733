import React from "react"

import { withMembershipSuccess } from "./withMembershipSuccess"
import { Button, Container, Emoji, Link, Title } from "./Membership.styled"

export const MembershipSuccess = withMembershipSuccess(
  ({ title, content }: Props): JSX.Element => (
    <>
      <Container width={`md`}>
        <Title>{title}</Title>
        <Emoji>🎉</Emoji>
        {content}
        <Button as={Link} to={"/mentors"} size={`large`} theme={`primary`} colour={`dark`}>
          Browse Mentors
        </Button>
      </Container>
    </>
  )
)

export interface Props {
  title?: string
  content?: any
  awaitingUpdate: boolean
  setAwaitingUpdate: (val: boolean) => void
}
